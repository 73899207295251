.products{
  width: 100%;
  background-color: #fff;
  height: auto;
  color: #000;
  padding-bottom: 40px;
  min-height: 80vh;
  h3{
    font-size: 24px;
    margin-bottom: 40px;
    text-align: center;
  }
  .products-wrapper{
    .product-item{
      padding: 20px 40px;
      box-shadow: 0 0 4px 0 #00000040;
      border-radius: 30px;
      margin-bottom: 30px;
      .item-img{
        display: flex;
        gap: 5px;
        align-items: center;
        margin-bottom: 15px;
        img{
          width: 25px;
        }
      }
      .item-info{
        text-indent: 10px;
        max-width: 80%;
        margin-bottom: 20px;
      }
      button{
        padding-left: 10px;
        font-size: 14px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: #1059FF;
        display: flex;
        align-items: center;
        transition: all .3s ease 0s;
        &:hover{
          transform: translateX(10px);
        }
        img{
          width: 15px;
          margin-left: 5px;
        }
      }
    }
  }
}

@media (screen and max-width: 767px) {
  .products{
    padding-bottom: 20px;
    h3{
      margin-bottom: 20px;
      font-size: 18px;
      text-align: center;
    }
    .products-wrapper{
      .product-item{
        padding: 15px 25px;
        border-radius: 10px;
        .item-img{
          margin-bottom: 10px;
          img{
            width: 20px;
          }
          h2{
            font-size: 16px;
          }
        }
        .item-info{
          max-width: 100%;
          margin-bottom: 15px;
          font-size: 14px;
          text-indent: 5px;
        }
      }
    }
  }
}
