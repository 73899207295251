.footer{
  width: 100%;
  position: relative;
  background-color: #fff;
  box-shadow: 0px -4px 10px 0px #78787840;
  padding: 40px 0 20px;
  z-index: 2;
  .footer-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .footer-logo {
      .logo {
        width: 80px;
        margin-bottom: 10px;
        cursor: pointer;
      }
      p {
        color: #AAAAAA;
        font-size: 18px;
      }
    }
    .footer-links{
      display: flex;
      a{
        color: #0D41E1;
        margin-top: 40px;
        cursor: pointer;
        margin-right: 25px;
        &:hover{
          opacity: .6;
        }
      }
    }
    .footer-network{
      button{
        width: 100%;
        color: #fff;
        background-color: #0D41E1;
        border-radius: 20px;
        border: none;
        padding: 15px 30px;
        font-size: 16px;
        margin-bottom: 10px;
        cursor: pointer;
        &:hover{
          opacity: .6;
        }
      }
      .networks{
        margin-bottom: 5px;
        display: flex;
        justify-content: center;
        gap: 15px;
        img{
          width: 30px;
          transition: all 0.3s ease 0s;
          &:hover{
            transition: all .3s ease 0s;
            transform: scale(1.2);
          }
        }
      }
      .network-mail{
        color: #AAAAAA;
      }
    }
  }
}

@media (screen and max-width: 767px){
  .footer{
    padding: 15px 0;
    .footer-wrapper{
      flex-direction: column;
      .footer-logo{
        margin-bottom: 20px;
       .logo{
         width: 80px;
         margin-bottom: 5px;
       }
        p{
          font-size: 14px;
        }
        .footer-links{
          flex-direction: column;
          margin-top: 20px;
          margin-right: 0;
          a{
            font-size: 14px;
            margin-top: 5px;
          }
        }
      }
      .footer-network{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        button{
          padding: 10px 30px;
          font-size: 14px;
          border-radius: 10px;
          margin-bottom: 5px;
        }
        .networks{
          margin: 5px 0;
          width: 100%;
          img{
            width: 20px;
          }
        }
        .network-mail{
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}