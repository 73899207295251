.__forget_password{
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 15px 20px 25px;
  justify-content: center;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2509803922);
  border-radius: 15px;
  background-color: #fff;
  @media (screen and max-width: 767px) {
    width: 75%;
    padding: 10px 20px 15px;
    position: absolute;
  }
  ._success{
    width: 40px;
    margin: 5px auto 0;
    @media (screen and max-width: 767px) {
      width: 30px;
    }
  }
  ._back{
    width: 30px;
    cursor: pointer;
    margin-bottom: 5px;
    @media (screen and max-width: 767px) {
      width: 20px;
    }
  }
  h3{
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
    @media (screen and max-width: 767px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  h5{
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    @media (screen and max-width: 767px) {
      font-size: 12px;
    }
  }
  h2{
    margin-bottom: 5px;
    @media (screen and max-width: 767px) {
      font-size: 20px;
      margin-bottom: 3px;
    }
  }
  p{
    margin-bottom: 15px;
    font-size: 18px;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  input{
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    border: none;
    box-shadow: 0 0 4px 0 #00000040;
    border-radius: 5px;
    margin: 10px 0;
    padding: 15px;
    position: relative;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      padding: 10px 15px;
      margin: 10px 0 5px;
    }
    &::placeholder{
      color: #C0C0C0;
    }
    &:focus{
      outline: 1px solid #0a194f;
    }
  }
  .invalid-input{
    box-shadow: 0 0 5px red;
  }
  .error-message{
    font-size: 12px;
    color: red;
    @media (screen and max-width: 767px) {
      font-size: 10px;
    }
  }
  button{
    background-color: #0D41E1FC;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    border:none;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all .3s ease 0s;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      margin-top: 10px;
    }
    &:hover{
      background-color: #4b74ff;
    }
  }
}