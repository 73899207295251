.partners{
  height: auto;
  color: #000;
  .partners-header {
    padding: 50px 0;
    background: linear-gradient(90deg, rgba(13,33,161,1) 0%, rgba(13,53,177,1) 33%, rgba(13,59,200,1) 70%, rgba(13,65,225,1) 100%);
    display: flex;
    align-items: center;
    @media (screen and max-width: 767px){
      height: auto;
      padding: 0 0 20px;
    }
  }
  .header-wrapper{
    display: flex;
    justify-content: space-between;
    padding-top: 100px;
    @media (screen and max-width: 767px){
      padding-top: 70px;
      flex-direction: column;
    }
    .partners-hero{
      color: #fff;
      width: 60%;
      @media (screen and max-width: 767px) {
        width: 100%;
      }
      h1{
        margin-bottom: 20px;
        line-height: 60px;
        @media (screen and max-width: 1024px) {
          font-size: 20px;
          line-height: 30px;
          text-align: center;
        }
      }
      p{
        max-width: 70%;
        font-size: 18px;
        line-height: 30px;
        text-indent: 10px;
        @media (screen and max-width: 1024px) {
          max-width: 100%;
          font-size: 16px;
          line-height: 20px;
        }
        @media (screen and max-width: 767px){
          font-size: 12px;
        }
      }
    }
    .partners-img{
      width: 50%;
      img{
        width: 100%;
        @media (screen and max-width: 767px){
          display: none;
        }
      }
    }
  }
  .form-wrapper {
    padding: 40px 0;
    @media (screen and max-width: 767px) {
      padding: 20px 0;
    }
    .form {
      background-color: #F2F6FE;
      padding: 60px 150px;
      border-radius: 25px;
      @media (screen and max-width: 1024px) {
        padding: 30px 80px;
      }
      @media (screen and max-width: 767px){
        padding: 20px;
      }
      h2{
        margin-bottom: 30px;
        @media (screen and max-width: 767px){
          font-size: 20px;
          text-align: center;
        }
        @media (screen and max-width: 320px){
          font-size: 16px;
          margin-bottom: 10px;
        }
      }
      .input-group{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;
        @media (screen and max-width: 767px){
          flex-direction: column;
          gap: 10px;
        }
        .form-input{
          width: 45%;
          display: flex;
          flex-direction: column;
          margin-bottom: 20px;
          @media (screen and max-width: 767px){
            width: 100%;
            margin-bottom: 10px;
          }
          @media (screen and max-width: 320px){
            margin-bottom: 5px;
          }
          label{
            color: #AAAAAA;
            margin-bottom: 10px;
            font-size: 18px;
            @media (screen and max-width: 767px){
              font-size: 16px;
            }
            @media (screen and max-width: 320px){
              font-size: 14px;
              margin-bottom: 5px;
            }
            span{
              color: red;
            }
          }
          input{
            box-sizing: border-box;
            border: none;
            border-radius: 25px;
            padding: 15px 30px;
            width: 100%;
            font-size: 16px;
            outline: none;
            &:focus{
              outline: 1px solid rgba(13, 65, 225, 0.9882352941);
            }
            @media (screen and max-width: 767px){
              padding: 10px 15px;
              font-size: 14px;
            }
            @media (screen and max-width: 320px){
              font-size: 12px;
              padding: 10px 15px;
            }
          }
          input.invalid{
            outline: 1px solid red;
            box-shadow: 0 0 5px red;
          }
          .custom-select{
            position: relative;
            width: 100%;
            .dropdown{
              width: 100%;
              position: absolute;
              background-color: #fff;
              padding: 5px 30px;
              border-radius: 20px;
              max-height: 200px;
              overflow: auto;
              font-size: 16px;
              box-sizing: border-box;
              @media (screen and max-width: 767px){
                padding: 10px 15px;
                font-size: 14px;
              }
              @media (screen and max-width: 320px){
                font-size: 12px;
                padding: 10px 15px;
              }
              .dropdown-item{
                cursor: pointer;
                transition: all .3s ease 0s;
                margin: 5px 0;
                &:hover{
                  color: #4f83ff;
                }
              }
            }
          }

          textarea{
            box-sizing: border-box;
            resize: none;
            width: 100%;
            min-height: 150px;
            border: none;
            border-radius: 20px;
            padding: 15px 30px;
            font-size: 16px;
            outline: none;
            &:focus{
              outline: 1px solid rgba(13, 65, 225, 0.9882352941);
            }
            @media (screen and max-width: 767px){
              padding: 10px 20px;
              font-size: 14px;
            }
            @media (screen and max-width: 320px){
              font-size: 12px;
              padding: 10px 15px;
            }
          }
          .error-message{
            font-size: 14px;
            text-align: center;
            margin-top: 5px;
            color: red;
            @media (screen and max-width: 767px){
              font-size: 10px;
            }
          }
        }
      }
      .partner-btn{
        margin-top: 60px;
        width: 43%;
        @media (screen and max-width: 767px){
          width: 100%;
          margin-top: 30px;
        }
        @media (screen and max-width: 320px){
          margin-top: 15px;
        }

        button{
          width: 100%;
          border: none;
          cursor: pointer;
          padding: 15px 30px;
          border-radius: 25px;
          background-color: #0D41E1FC;
          color: #fff;
          font-size: 20px;
          font-weight: 600;
          margin-bottom: 10px;
          @media (screen and max-width: 767px){
            font-size: 14px;
            padding: 10px 20px;
          }
          @media (screen and max-width: 320px){
            font-size: 12px;
          }
        }
        .form-agree{
          .agree-text{
            width: 100%;
            cursor: pointer;
            font-size: 16px;
            color: #C0C0C0;
            line-height: 23.44px;
            text-align: center;
            @media (screen and max-width: 767px){
              text-align: left;
              font-size: 12px;
              display: flex;
              align-items: center;
              line-height: 12px;
            }
            input{
              margin-right: 5px;
              @media (screen and max-width: 767px){
                width: 10px;
              }
            }
            label{
              cursor: pointer;
              a{
                color: #0F43E1;
                font-weight: 600;
              }
              span{
                color: red;
              }
            }
          }
          .error-message{
            color: red;
            margin-top: 5px;
            font-size: 14px;

            text-align: center;
            @media (screen and max-width: 767px) {
              font-size: 10px;
              margin-left: 20px;
              text-align: left;
            }
          }
        }
      }
    }
  }
}