.service-card {
  padding: 20px;
  border-radius: 5px;
  width: 25%;
  background-color: #fff;
  color: #000;
  margin: 20px;
  cursor: pointer;
  @media (screen and max-width: 767px) {
    width: 80%;
    padding: 15px;
    margin: 15px;
  }
  .__image{
    width: 100%;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 50%;
      @media (screen and max-width: 767px) {
        width: 80%;
      }
    }
  }
  h2{
    margin: 0 0 10px;
    padding-top: 50px;
    @media (screen and max-width: 767px) {
      font-size: 12px;
    }
  }
  h5{
    margin-bottom: 30px;
    text-align: left;
    text-indent: 10px;
    @media (screen and max-width: 767px) {
      font-size: 10px;
      text-indent: 0;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  button{
    padding-left: 10px;
    font-size: 14px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: rgb(44, 0, 255);
    display: flex;
    align-items: center;
    transition: all .3s ease 0s;
    @media (screen and max-width: 767px) {
      font-size: 10px;
    }
    &:hover{
      transform: translateX(10px);
    }
    img{
      width: 15px;
      margin-left: 5px;
      @media (screen and max-width: 767px) {
        width: 10px;
      }
    }
  }
}

