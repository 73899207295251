.orderInfo{
  position: relative;
  width: 100%;
  padding: 60px 0;
  background-color: #fff;
  background-size: contain;
  @media (screen and max-width: 767px) {
    padding: 40px 0 0;
    background-position: center;
  }
  h2{
    text-align: center;
    margin-bottom: 40px;
    font-size: 30px;
    @media (screen and max-width: 767px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  &__cards{
    padding: 50px 0;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 150px;
    @media (screen and max-width: 767px) {
      gap: 30px;
      padding: 20px 0;
      flex-direction: column;
      align-items: center;

    }
  }
}