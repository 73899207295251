.partners-banner{
  padding: 30px 0;
  position: relative;
  background-color: #fff;
  .banner-wrapper{
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 40px;
    background: linear-gradient(90deg, rgba(13,33,161,1) 4%, rgba(13,53,177,1) 26%, rgba(13,59,200,1) 66%, rgba(13,65,225,1) 100%);
    padding: 30px 0;
    h1{
      margin-bottom: 25px;
    }
    p{
      text-align: center;
      max-width: 70%;
      margin-bottom: 30px;
    }
    button{
      background-color: rgba(256, 256, 256, .2);
      color: #fff;
      border: none;
      padding: 10px 30px;
      font-size: 18px;
      border-radius: 30px;
      cursor: pointer;
      &:hover{
        opacity: .8;
      }
    }
  }
}
@media (screen and max-width: 767px) {
  .partners-banner{
    background: linear-gradient(90deg, rgba(13,33,161,1) 4%, rgba(13,53,177,1) 26%, rgba(13,59,200,1) 66%, rgba(13,65,225,1) 100%);
    padding: 0 15px;
    border-radius: 5px;
    .container{
      padding: 0;
    }
    .banner-wrapper{
      padding: 20px 0;
      border-radius: 20px;
      h1{
        font-size: 20px;
        margin-bottom: 20px;
      }
      p{
        font-size: 12px;
        max-width: inherit;
        margin-bottom: 20px;
      }
      button{
        font-size: 14px;
        padding: 10px 20px;
        border-radius: 10px;
      }
    }
  }
}