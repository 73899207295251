.faq{
  padding-top: 60px;
  background-color: #fff;
  padding-bottom: 60px;
  position: relative;
  h1{
    margin-bottom: 30px;
    color: #000;
    text-align: center;
  }
  .accordion{
    width: 100%;
  }
  .item{
    background-color: #fff;
    margin-bottom: 15px;
    border-radius: 25px;
    padding: 20px 30px;
    box-shadow: 0 0 4px 0 #00000040;
    cursor: pointer;
    .title{
      display: flex;
      justify-content: space-between;
      align-items: center;
      h4{
        color: #212121;
      }
      img{
        width: 20px;
        transition: all .3s ease 0s;
      }
      .spin{
        transition: all .3s ease 0s;
        transform: rotate(-180deg);
      }
    }
    .content{
      padding: 0 20px 0 5px;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
      p{
        color: #212121;
      }
    }
    .content.active{
      margin-top: 10px;
      max-height: 1000px;
      transition: max-height 0.8s cubic-bezier(0, 1, 0, 1);
    }
  }
}

@media (screen and max-width: 767px) {
  .faq{
    padding-top: 30px;
    padding-bottom: 40px;
    h1{
      font-size: 16px;
      margin-bottom: 20px;
    }
    .accordion{
      .item{
        padding: 10px 15px;
        margin-bottom: 10px;
        border-radius: 10px;
        .title{
          h4{
            font-size: 12px;
          }
          img{
            width: 10px;
          }
        }
        .content{
          padding-left: 3px;
          p{
            font-size: 11px;
          }
        }
        .content.active{
          margin-top: 5px;
        }
      }
    }
  }
}