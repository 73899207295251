.cookie-consent-form {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #0a194f;
  z-index: 5;
  color: #fff;
  .consent-message {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 15px 60px;
    @media (screen and max-width: 767px) {
      padding: 10px;
      flex-direction: column;
    }
    p {
      font-weight: 500;
      max-width: 80%;
      @media (screen and max-width: 767px) {
        font-size: 14px;
        max-width: 100%;
      }
      span{
        color: #4270ff;
        text-decoration: underline;
        cursor: pointer;
        &:hover{
          opacity: .6;
        }
      }
    }
    .btns{
      display: flex;
      @media (screen and max-width: 767px) {
        flex-direction: column;
      }
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      background-color: transparent;
      color: #fff;
      border: 1px solid #fff;
      cursor: pointer;
      padding: 10px 30px;
      border-radius: 10px;
      @media (screen and max-width: 767px) {
        font-size: 14px;
        width: 100%;
      }
      &:last-child{
        background-color: #0a194f;
        color: #fff;
        border: 1px solid #0a194f;
        margin-left: 10px;
        @media (screen and max-width: 767px) {
          margin-left: 0;
        }
      }
    }
  }
}