.about {
  position: relative;
  background-color: #fff;
  padding: 60px 0;
  .about-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .about-image {
      width: 55%;
      img {
        max-width: 80%;
        height: auto;
        border-radius: 20px;
      }
    }
    .about-text {
      width: 70%;
      text-indent: 10px;
      h2 {
        font-size: 24px;
        margin-bottom: 20px;
        color: #333;
      }
      p {
        font-size: 16px;
        line-height: 1.5;
        color: #555;
      }
    }
  }
}

@media (screen and max-width: 767px){
  .about{
    padding: 20px 0;
    .about-content{
      flex-direction: column;
      .about-image{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex: 1;
        margin-bottom: 15px;
        img{
          width: 100%;
          margin: 0 auto;
        }
      }
      .about-text{
        width: 100%;
        flex: 2;
        text-align: left;
        h2{
          margin-bottom: 5px;
          text-align: center;
          font-size: 20px;
        }
        p{
          font-size: 14px;
        }
      }
    }
  }
}