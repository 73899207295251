.product-info{
  padding: 30px 0 60px;
  min-height: 70vh;
  @media (screen and max-width: 767px) {
    padding: 0 0 30px;
  }
  .product-title{
    background: #CEDEFD40;
    border-radius: 25px;
    display: flex;
    justify-content: space-between;
    padding: 15px 20px;
    margin-bottom: 30px;
    @media (screen and max-width: 767px) {
      padding: 10px;
      margin-bottom: 20px;
    }
    .product-logo{
      width: 50%;
      display: flex;
      gap: 20px;
      align-items: center;
      @media (screen and max-width: 767px) {
        gap: 5px;
      }
      img{
        width: 80px;
        @media (screen and max-width: 767px) {
            width: 30px;
        }
      }
      .logo-name{
        h1{
          color: #212121;
          @media (screen and max-width: 767px) {
            font-size: 20px;
          }
        }
        p{
          color: #707070;
          font-weight: 500;
          font-size: 16px;
          @media (screen and max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }
    .purchase-info{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      @media (screen and max-width: 767px) {
        gap: 5px;
      }
      p{
        color: #707070;
        font-size: 20px;
        font-weight: 600;
        @media (screen and max-width: 767px) {
          font-size: 14px;
        }
        span{
          font-weight: 700;
          font-size: 26px;
          color: #000;
          @media (screen and max-width: 767px) {
            font-size: 18px;
          }
        }
      }
      .button-group{
        border-radius: 16px;
        padding: 10px 15px;
        background-color: #F3F7FE;
        @media (screen and max-width: 767px){
          padding: 8px;
        }
        button{
          border-radius: 10px;
          border: none;
          padding: 10px 20px;
          background-color: transparent;
          font-size: 20px;
          cursor: pointer;
          font-weight: 600;
          color: #808080;
          @media (screen and max-width: 767px) {
            padding: 5px;
            font-size: 12px;
          }
        }
        .active-button{
          background-color: #fff;
          color: #000;
        }
      }
      .counter{
        display: flex;
        align-items: center;
        padding: 5px 20px;
        background-color: #fff;
        border-radius: 10px;
        @media (screen and max-width: 767px) {
          padding: 5px 10px;
        }
        p{
          margin: 0 25px;
          font-weight: 600;
          color: #000;
          @media (screen and max-width: 767px) {
            margin: 0 15px;
          }
        }
        button{
          background-color: transparent;
          border: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 100%;
          width: 100%;
          img{
            width: 12px;
          }
        }
      }
    }
  }
  .info{
    margin-bottom: 40px;
    @media (screen and max-width: 767px) {
      margin-bottom: 20px;
    }
    h2{
      margin-bottom: 10px;
      @media (screen and max-width: 767px) {
        font-size: 18px;
        margin-bottom: 5px;
      }
    }
    p{
      text-indent: 10px;
      @media (screen and max-width: 767px) {
        text-indent: 5px;
        font-size: 14px;
      }
    }
  }
  .total-price{
    margin-bottom: 15px;
    p{
      color: #AAAAAA;
      font-weight: 600;
      font-size: 20px;
      @media (screen and max-width: 767px) {
        font-size: 16px;
      }
      span{
        color: #000;
        font-weight: 700;
      }
    }
  }
  .purchase-btn{
    background: #0D41E1FC;
    border: none;
    color: #fff;
    font-size: 18px;
    padding: 10px 30px;
    border-radius: 15px;
    font-weight: 600;
    cursor: pointer;
    margin-bottom: 60px;
    transition: all .3s ease 0s;
    @media (screen and max-width: 767px) {
      font-size: 16px;
      margin-bottom: 30px;
    }
    &:hover{
      background-color: #4f83ff;
    }
  }
  .purchase-btn.soon{
    cursor: auto;
    background-color: #98b4ff;
  }
  .info-video{
    text-align: center;
    h3{
      color: #212121;
      font-size: 24px;
      margin-bottom: 40px;
      @media (screen and max-width: 767px) {
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
    img{
      width: 80%;
      @media (screen and max-width: 767px) {
        width: 100%;
      }
    }
  }
}