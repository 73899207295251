body.lock {
  overflow: hidden;
}
.__modal_pay {
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all .5s ease-in-out 0s;
  z-index: 999;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .__modal_content {
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    transform: scale(.5);
    transition: all .5s ease-in-out 0s;

    &.active {
      transform: scale(1);
    }

    @media (screen and max-width: 767px) {
      padding: 15px;
    }

    .__pay {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: #fff;
      min-width: 30vw;
      border-radius: 10px;
      color: #000;
      padding: 40px;
      @media (screen and max-width: 767px) {
        padding: 20px 15px;
        min-width: 50vw;
      }

      p {
        font-size: 22px;
        text-align: center;
        font-weight: 600;
        margin-bottom: 30px;
        @media (screen and max-width: 767px) {
          margin-bottom: 15px;
          font-size: 12px;
        }
      }

      .btn_group {
        font-size: 16px;
        display: flex;
        justify-content: center;
        gap: 20px;
        @media (screen and max-width: 767px) {
          gap: 10px;
        }

        button {
          color: #fff;
          background-color: #0D41E1;
          border: none;
          padding: 10px 20px;
          border-radius: 5px;
          cursor: pointer;
          @media (screen and max-width: 767px) {
            font-size: 10px;
            padding: 5px 10px;
          }

          &:last-child {
            background-color: #9D9D9D;
          }
        }
      }
    }
  }
}