.change_email_wrapper{
  margin-top: 40px;
  background-color: #F2F6FE;
  color: #000;
  border-radius: 10px;
  padding: 30px;
  width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (screen and max-width: 767px) {
    padding: 20px;
    width: 100%;
  }
  h3{
    margin-bottom: 25px;
    font-size: 22px;
    @media (screen and max-width: 767px) {
      font-size: 16px;
      text-align: center;
    }
  }
  p{
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 15px;
    text-decoration: underline;
    @media (screen and max-width: 767px) {
      font-size: 10px;
      margin-bottom: 10px;
    }
  }
  .password-container{
    width: 100%;
    position: relative;
    img{
      width: 20px;
      position: absolute;
      top: 35%;
      right: 20px;
      cursor: pointer;
      @media (screen and max-width: 767px) {
        width: 15px;
        top: 40%;
        right: 10px;
      }
    }
  }
  input{
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    border: none;
    box-shadow: 0 0 4px 0 #00000040;
    border-radius: 10px;
    margin: 10px 0;
    padding: 13px;
    position: relative;
    @media (screen and max-width: 767px) {
      font-size: 10px;
      padding: 5px 10px;
      margin: 5px 0 0;
      border-radius: 5px;
    }
    &::placeholder{
      color: #C0C0C0;
    }
    &:focus{
      outline: 1px solid #0a194f;
    }
  }

  .invalid-input{
    box-shadow: 0 0 5px red;
  }
  .error-message{
    font-size: 12px;
    color: red;
    @media (screen and max-width: 767px) {
      font-size: 10px;
    }
  }
  button{
    background-color: rgb(13, 59, 200);
    color: #fff;
    border-radius: 15px;
    padding: 10px;
    border:none;
    font-size: 16px;
    margin-top: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all .3s ease 0s;
    width: 40%;
    @media (screen and max-width: 767px) {
      padding: 5px;
      font-size: 10px;
      margin-top: 10px;
      width: 100%;
    }
    &:hover{
      background-color: #bebebe;
    }
  }
}