.__input_group{
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  box-shadow: 0 0 6px 0 #00000040;
  padding: 70px 30px 20px;
  border-radius: 20px;
  background-color: #fff;
  @media (screen and max-width: 767px) {
    padding: 40px 30px 15px;
  }
  .password-container{
    width: 100%;
    position: relative;
    img{
      width: 20px;
      position: absolute;
      top: 35%;
      right: 20px;
      cursor: pointer;
      @media (screen and max-width: 767px) {
        width: 15px;
        top: 40%;
        right: 10px;
      }
    }
  }
  input{
    width: 100%;
    box-sizing: border-box;
    font-size: 18px;
    border: none;
    box-shadow: 0 0 4px 0 #00000040;
    border-radius: 10px;
    margin: 10px 0;
    padding: 15px;
    position: relative;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      padding: 10px 15px;
    }
    &::placeholder{
      color: #C0C0C0;
    }
    &:focus{
      outline: 1px solid #0a194f;
    }
  }

  .invalid-input{
    box-shadow: 0 0 15px red;
  }
  .error-message{
    font-size: 12px;
    color: red;
    @media (screen and max-width: 767px) {
      font-size: 10px;
    }
  }
  .__btn{
    background-color: #0D41E1FC;
    color: #fff;
    border-radius: 15px;
    padding: 10px;
    border:none;
    font-size: 18px;
    margin-top: 20px;
    font-weight: 600;
    cursor: pointer;
    transition: all .3s ease 0s;
    @media (screen and max-width: 767px) {
      padding: 5px;
      border-radius: 10px;
      font-size: 12px;
      margin-top: 10px;
    }
    &:hover{
      background-color: #4b74ff;
    }
  }
  .login_btns{
    .recover__btn{
      margin: 15px 0 5px;
      color: #0F43E1;
      background-color: transparent;
      border: none;
      font-size: 16px;
      cursor: pointer;
      @media (screen and max-width: 767px) {
        margin: 10px 0 3px;
        font-size: 10px;
      }
    }
    .register-btn{
      display: flex;
      align-items: center;
      justify-content: center;
      p{
        font-size: 16px;
        margin-right: 5px;
        color: #C0C0C0;
        @media (screen and max-width: 767px) {
          font-size: 10px;
        }
      }
      button{
        color: #0F43E1;
        background-color: transparent;
        border: none;
        font-size: 16px;
        cursor: pointer;
        font-weight: 600;
        @media (screen and max-width: 767px) {
          font-size: 10px;
        }
      }
    }
  }

  .__direct{
    margin-top: 15px;
    color: #C0C0C0;
    @media (screen and max-width: 767px) {
      margin-top: 10px;
      font-size: 10px;
    }
    button{
      color: #0F43E1;
      font-weight: 600;
      background-color: transparent;
      border: none;
      cursor: pointer;
      font-size: 15px;
      @media (screen and max-width: 767px) {
        font-size: 10px;
      }
    }
  }
}