
.pay_in_wrapper{
  padding: 0 40px;
  width: 80%;
  @media (screen and max-width: 767px) {
    padding: 0 10px;
    width: 95%;
  }
  h2{
    margin-bottom: 40px;
    @media (screen and max-width: 767px) {
      margin-bottom: 20px;
      font-size: 16px;
    }
    @media (screen and max-width: 375px) {
      margin-bottom: 5px;
      font-size: 14px;
    }
  }
  .__pay_in{
    background-color:  #F2F6FE;
    border-radius: 10px;
    padding: 25px;
    margin-bottom: 80px;
    @media (screen and max-width: 767px) {
      padding: 20px 15px;
      margin-bottom: 40px;
    }
    @media (screen and max-width: 480px) {
      width: initial;
      padding: 15px;
    }
    .__form_wrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      .__form{
        width: 40%;
        display: flex;
        flex-direction: column;
        @media (screen and max-width: 767px) {
          width: 100%;
        }
        .__pay_input{
          margin-bottom: 20px;
          @media (screen and max-width: 767px) {
            margin-bottom: 10px;
          }
          .__form_input{
            display: flex;
            flex-direction: column;
            width: 100%;
            .__input_icon_container{
              position: relative;
              .__input{
                box-sizing: border-box;
                background-color: #F2F6FE;
                font-size: 18px;
                line-height: 32px;
                font-weight: 400;
                height: 32px;
                border: none;
                outline: none;
                border-bottom: 2px solid #1c1c2829;
                margin-bottom: 4px;
                padding: 0 10px;
                transition: all .5s ease-in-out;
                color: #060614f5;
                width: 100%;
                @media (screen and max-width: 767px) {
                  font-size: 12px;
                  line-height: 20px;
                  height: 24px;
                  padding: 0 5px;
                }
                &.hovered{
                  border-bottom: 2px solid #000;
                }
              }
              .__icon_container{
                position: absolute;
                right: 0;
                bottom: 8px;
                width: 100%;
                height: 100%;
                max-width: 32px;
                max-height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                cursor: pointer;
                transition: all .5s ease-in-out;
                opacity: 0;
                @media (screen and max-width: 767px) {
                  max-width: 20px;
                  max-height: 20px;
                }
                &.hovered{
                  opacity: 1;
                }
              }
            }
            .error-message{
              color: #ff3131;
              text-align: center;
              font-size: 12px;
              margin-top: 5px;
              @media (screen and max-width: 767px) {
                font-size: 8px;
                margin-top: 0;
              }
            }
          }
        }
        button{
          border: none;
          cursor: pointer;
          font-size: 16px;
          background-color: #5476e5;
          color: #fff;
          padding: 15px 30px;
          border-radius: 5px;
          font-weight: 600;
          transition: all 0.2s ease 0s;
          @media (screen and max-width: 767px) {
            font-size: 12px;
            padding: 8px 10px;
          }
          &:hover{
            opacity: .7;
          }
        }
      }
    }

  }
  .__image{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    @media (screen and max-width: 767px) {
      display: none;
    }
    img{
      width: 50%;
    }
  }
}
