.__reset_password{
  width: 100%;
  height: 100vh;
  background-color: #fff;
  .__wrapper {
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    .__image {
      width: 600px;
      @media (screen and max-width: 1240px) {
        width: 400px;
      }
      @media (screen and max-width: 767px) {
        position: absolute;
        filter: blur(5px);
      }
      img {
        width: 100%;
      }
    }
    .change_password_wrapper{
      width: 40%;
      background-color: #fff;
      box-shadow: 0 0 6px 0 #00000040;
      @media (screen and max-width: 767px) {
        width: 70%;
        position: absolute;
        margin-top: 0;
        h3{
          margin-bottom: 10px;
        }
      }
    }
  }
}