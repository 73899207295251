body.lock {
  overflow: hidden;
}
.__modal_contact{
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: all .5s ease-in-out 0s;
  z-index: 999;
  &.active{
    opacity: 1;
    pointer-events: all;
  }
  .__modal_content{
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    transform: scale(.5);
    transition: all .5s ease-in-out 0s;
    &.active{
      transform: scale(1);
    }
    @media (screen and max-width: 767px) {
      padding: 15px;
      max-width: 85%;
    }
    .form {
      @media (screen and max-width: 1024px) {
        padding: 30px 80px;
      }
      @media (screen and max-width: 767px){
        padding: 0;
      }
      h2{
        margin-bottom: 20px;
        text-align: center;
        span{
          color: #0D3BC8;
        }
        @media (screen and max-width: 767px){
          font-size: 14px;
        }
      }
      .input-group{
        display: flex;
        flex-direction: column;
        gap: 5px;
        .form-input{
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-bottom: 5px;
          @media (screen and max-width: 320px){
            margin-bottom: 5px;
          }
          label{
            color: #000;
            margin-bottom: 10px;
            font-size: 18px;
            text-align: left;
            @media (screen and max-width: 767px){
              font-size: 12px;
              margin-bottom: 5px;
            }
            span{
              color: red;
            }
          }
          input{
            box-sizing: border-box;
            width: 100%;
            color: #000000;
            border: 1px solid #c9c9c9;
            border-radius: 5px;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            outline: none;
            margin: 0;
            height: 40px;
            padding: 10px;
            font-size: 16px;
            line-height: 1.33;
            &:focus{
              outline: 1px solid rgba(13, 65, 225, 0.9882352941);
            }
            @media (screen and max-width: 767px){
              height: 30px;
              padding: 5px;
              font-size: 12px;
              border-radius: 5px;
            }
          }
          textarea{
            box-sizing: border-box;
            width: 100%;
            color: #000000;
            border: 1px solid #c9c9c9;
            border-radius: 5px;
            -moz-border-radius: 5px;
            -webkit-border-radius: 5px;
            outline: none;
            margin: 0;
            height: 80px;
            padding: 10px;
            font-size: 16px;
            resize: none;
            &:focus{
              outline: 1px solid rgba(13, 65, 225, 0.9882352941);
            }
            @media (screen and max-width: 767px){
              height: 60px;
              padding: 5px;
              font-size: 12px;
              border-radius: 5px;
            }
          }
          input.invalid{
            outline: 1px solid red;
            box-shadow: 0 0 5px red;
          }
          .error-message{
            font-size: 14px;
            text-align: center;
            margin-top: 5px;
            color: red;
            @media (screen and max-width: 767px){
              font-size: 10px;
            }
          }
        }
        .form-input-file {

          input[type="file"] {
            display: none;
          }

          .custom-file-upload {
            display: inline-block;
            box-sizing: border-box;
            width: 100%;
            padding: 10px;
            cursor: pointer;
            background-color: transparent;
            color: #000;
            border: 1px solid #c9c9c9;
            border-radius: 4px;
            text-align: center;
          }
          .file-preview {
            margin-top: 10px;

            p {
              margin: 0 0 5px 0;
              font-weight: bold;
            }

            img {
              max-width: 60px;
              max-height: 30px;
              border: 1px solid #ccc;
              border-radius: 4px;
            }

            a {
              color: #007bff;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }
      .btn{
        width: 100%;
        margin-top: 20px;
        @media (screen and max-width: 767px){
          margin-top: 10px;
        }

        button{
          width: 100%;
          border: none;
          cursor: pointer;
          padding: 10px;
          border-radius: 10px;
          background-color: #0D41E1FC;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
          @media (screen and max-width: 767px){
            font-size: 12px;
            padding: 8px 20px;
          }
        }
        .form-agree{
          .agree-text{
            width: 100%;
            cursor: pointer;
            font-size: 16px;
            color: #C0C0C0;
            line-height: 23.44px;
            text-align: center;
            @media (screen and max-width: 767px){
              text-align: left;
              font-size: 10px;
              display: flex;
              align-items: center;
              line-height: 10px;
            }
            input{
              margin-right: 5px;
              @media (screen and max-width: 767px){
                width: 10px;
              }
            }
            label{
              cursor: pointer;
              a{
                color: #0F43E1;
                font-weight: 600;
              }
              span{
                color: red;
              }
            }
          }
          .error-message{
            color: red;
            margin-top: 5px;
            font-size: 14px;
            text-align: center;
            @media (screen and max-width: 767px) {
              font-size: 10px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}