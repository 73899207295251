.__confirm_email{
  display: flex;
  flex-direction: column;
  width: 30%;
  padding: 25px 20px;
  justify-content: center;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.2509803922);
  border-radius: 15px;
  background-color: #fff;
  @media (screen and max-width: 767px) {
    width: 75%;
    position: absolute;
  }
  h2{
    margin-bottom: 15px;
    @media (screen and max-width: 767px) {
      font-size: 20px;
      margin-bottom: 10px;
    }
  }
  p{
    color: #737373;
    margin-bottom: 10px;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  span{
    color: #003eff;
    text-decoration: underline;
    margin-bottom: 30px;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
  button{
    background-color: #cccccc;
    color: #000;
    border: none;
    padding: 10px 20px;
    border-radius: 15px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      border-radius: 10px;
    }
    &:hover{
      opacity: .6;
    }
  }
}