.login{
  width: 100%;
  height: 100vh;
  background-color: #fff;
  .__wrapper{
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .__image{
      width: 600px;
      @media (screen and max-width: 1240px) {
        width: 400px;
      }
      @media (screen and max-width: 767px) {
        position: absolute;
      }
      img{
        width: 100%;
      }
    }
    .__form{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 35%;
      @media (screen and max-width: 767px) {
        width: 75%;
      }
      .form-agree{
        padding-top: 15px;
        .agree-text{
          display: flex;
          align-items: center;
          width: fit-content;
          cursor: pointer;
        }
        input{
          cursor: pointer;
          width: 20px;
        }
        label{
          width: 100%;
          font-size: 14px;
          line-height: 130%;
          color: #9D9D9D;
          display: flex;
          @media (screen and max-width: 767px) {
            font-size: 10px;
          }
          a{
            color: #003aff;
            text-decoration: underline;
            margin-left: 5px;
            &:hover{
              opacity: .7;
            }
          }
          span{
            color: #ff0000;
            margin-left: 2px;
            font-size: 16px;
          }
        }
        .error-message{
          color: red;
          margin-top: 5px;
          font-size: 12px;
          text-align: center;
        }
      }
    }
  }
}