.header{
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .header-title{
    position: absolute;
    z-index: 1;
    h1{
      color: #000;
      font-size: 45px;
      margin-bottom: 20px;
      .typed-text{
        color: #1059FF;
      }
    }
    button{
      font-size: 26px;
      background-color: #1059FF;
      border: 1px solid #1059FF;
      border-radius: 35px;
      padding: 20px 60px;
      color: #fff;
      cursor: pointer;
      transition: all .3s ease 0s;
      &:hover{
        box-shadow: 0 0 10px #1059FF,
                    0 0 30px #1059FF,
                    0 0 80px #1059FF;
      }
    }
  }
  .particles-container{
    width: 100%;
    height: 100vh;
    .particles{

    }
  }

}
@media (screen and max-width: 767px){
  .header {
    .header-title {
      h1 {
        font-size: 28px;
      }
      button{
        font-size: 20px;
        padding: 10px 40px;
      }
    }
  }
}