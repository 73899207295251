.__menu{
  width: 100%;
  .__item{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
    @media (screen and max-width: 767px) {
      margin-bottom: 15px;
    }
    &:last-child{
      margin-top: 60px;
      @media (screen and max-width: 767px) {
        margin-top: 50px;
      }
    }
    .__list_item{
      width: 100%;
      box-sizing: border-box;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 10px;
      border-radius: 5px;
      transition: all .4s ease-in-out 0s;
      &:hover{
        background-color: #F2F6FE;
        cursor: pointer;
      }
      span{
        color: #000;
        font-size: 16px;
        transition: all .4s ease-in-out 0s;
        @media (screen and max-width: 767px) {
          display: none;
        }
      }
      .__image{
        width: 30px;
        @media (screen and max-width: 767px) {
          width: 20px;
        }
        .__profile_svg{
          fill: #000;
          transition: all .4s ease-in-out 0s;
          width: 30px;
          @media (screen and max-width: 767px) {
            width: 20px;
          }
        }
      }

    }
    .__list_item.selected{
      background-color: rgb(13, 59, 200);
      span{
        color: #fff;
      }
      .__profile_svg{
        fill: #fff;
      }
    }
  }
}