.spinner-container {
  position: relative;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgb(196, 196, 196);
  border-top-color: #707070;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}