.__profile{
  background-color: #fff;
  color: #000;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;
  @media (screen and max-width: 767px) {
    min-height: 75vh;
  }
  .__wrapper{
    display: flex;
    width: 100%;
    height: 100%;
    .__menu_container{
      width: 325px;
      padding: 5px;
      height: 100%;
      @media (screen and max-width: 767px) {
        width: max-content;
      }
    }
    .__content_container{
      padding: 5px 20px;
      width: 100%;
      height: 100%;
      @media (screen and max-width: 767px) {
        padding: 5px 10px;
      }
    }
  }
}