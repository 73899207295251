.App {
    width: 100%;
    min-height: 100vh;
}

.blur{
    filter: blur(3px);
    pointer-events: none;
}


