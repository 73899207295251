.orderInfo__card {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 10%;
  gap: 20px;
  @media (screen and max-width: 767px) {
    gap: 5px;
    max-width: 70%;
  }
  .card__image {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (screen and max-width: 767px) {
      width: 50px;
    }
  }
  .card__content {
    text-align: center;
  }
  .card__title{
    margin-bottom: 10px;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      margin-bottom: 5px;
    }
  }
  .card__text {
    @media (screen and max-width: 767px) {
      font-size: 12px;
    }
  }
}