.products{
  .__products_wrapper{
    padding: 0 40px;
    width: 80%;
    @media (screen and max-width: 767px) {
      padding: 0 20px;
      width: 90%;
    }
    h1{
      margin-bottom: 40px;
      @media (screen and max-width: 767px) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    .product-item{
      padding: 20px 40px;
      box-shadow: 0 0 4px 0 #00000040;
      border-radius: 30px;
      margin-bottom: 30px;
      @media (screen and max-width: 767px) {
        padding: 15px 20px;
        margin-bottom: 20px;
      }
      h2{
        @media (screen and max-width: 767px) {
          font-size: 14px;
        }
      }
      p{
        @media (screen and max-width: 767px) {
          font-size: 10px;
        }
      }
      .__steps{
        .__process{
          margin: 60px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (screen and max-width: 767px) {
            margin: 20px 0;
          }
          .__column{
            width: 100%;
            height: auto;
            min-height: 40px;
            padding: 0;
            position: relative;
            display: flex;
            justify-content: center;
            border-top: 3px #9D9D9D solid;
            @media (screen and max-width: 767px) {
              min-height: 20px;
            }
            .line{
              position: absolute;
              top: -5px;
              width: 50%;
              height: 10px;
              background-color: #fff;
              z-index: 3;
              &.start{
                left: 0;
              }
              &.end{
                right: 0;
              }
            }
            .circle{
              height: 20px;
              width: 20px;
              border-radius: 50%;
              display: block;
              position: absolute;
              left: 50%;
              top: -11px;
              z-index: 4;
              transform: translateX(-50%);
              background-color: #9D9D9D;
              padding: 0;
              @media (screen and max-width: 767px) {
                height: 10px;
                width: 10px;
                top: -6px;
              }
              &.done{
                background-color: #00ff00;
              }
            }
            .title{
              font-weight: 400;
              margin: 0;
              padding-top: 20px;
              @media (screen and max-width: 767px) {
                padding-top: 5px;
              }
              h5{
                font-size: 18px;
                color: #000;
                @media (screen and max-width: 767px) {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
      .__purchase{
        //display: flex;
        //justify-content: space-between;
        //align-items: center;
        .total-price{
          margin-bottom: 15px;
          @media (screen and max-width: 767px) {
            margin-bottom: 0;
          }
          p{
            color: #AAAAAA;
            font-weight: 600;
            font-size: 20px;
            @media (screen and max-width: 767px) {
              font-size: 10px;
            }
            span{
              color: #000;
              font-weight: 700;
            }
          }
        }
        button{
          padding: 10px 20px;
          border-radius: 10px;
          font-size: 14px;
          background-color: #1059FF;
          border: none;
          cursor: pointer;
          color: #fff;
          transition: all .3s ease 0s;
          @media (screen and max-width: 767px) {
            font-size: 10px;
            padding: 5px 8px;
            border-radius: 5px;
          }
          &:disabled{
            background-color: #9D9D9D;
            cursor: default;
          }
        }
      }

    }
  }
  .__empty{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    padding-top: 25vh;
    @media (screen and max-width: 767px) {
      padding-top: 20vh;
      width: 95%;
    }
    .__image{
      width: 80px;
      margin-bottom: 10px;
      @media (screen and max-width: 767px) {
        width: 35px;
        margin-bottom: 5px;
      }
      img{
        width: 100%;
      }
    }
    h2{
      color: #0D3BC8;
      @media (screen and max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}