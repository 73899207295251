body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*::-webkit-scrollbar {
  width: 10px;
}
*::-webkit-scrollbar-track {
  background-color: #fff;
}
*::-webkit-scrollbar-thumb {
  background-color: #0D41E1;
  border-radius: 5px;
  border: 2px solid #fff;
}
*::-webkit-scrollbar-thumb:hover {
  background-color: #2e5fff;
}
*::-webkit-scrollbar-thumb:active {
  background-color: #0032c7;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
h1, h2, h3, h4, h5, h6, p{
  margin: 0;
  padding: 0;
}
ul{
  margin: 0;
  padding: 0;
}
li{
  margin: 0;
  padding: 0;
  list-style: none;
}
a{
  text-decoration: none;
}
.container{
  padding: 0 10vw;
}
.container-top{
  padding-top: 100px;
}

@media (max-width: 767px){
  .container{
    padding: 0 7vw;
  }
  .container-top{
    padding-top: 75px;
  }
}
