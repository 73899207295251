.service_profile{
  .__service_profile_wrapper{
    padding: 0 40px;
    width: 80%;
    @media (screen and max-width: 767px) {
      padding: 0 10px;
      width: 95%;
    }
    h1{
      margin-bottom: 40px;
      @media (screen and max-width: 767px) {
        font-size: 16px;
        margin-bottom: 20px;
      }
    }
    .__service_profile_item{
      padding: 20px 40px;
      box-shadow: 0 0 4px 0 #00000040;
      border-radius: 30px;
      margin-bottom: 30px;
      &.finished{
        background-color: #f0f0f0;
        opacity: 0.6;
      }
      @media (screen and max-width: 767px) {
        padding: 15px 20px;
        margin-bottom: 20px;
      }
      .__title{
        display: flex;
        justify-content: space-between;
        align-items: center;
        h2{
          @media (screen and max-width: 767px) {
            font-size: 16px;
          }
        }
        h6{
          font-size: 16px;
          @media (screen and max-width: 767px) {
            font-size: 10px;
          }
        }
      }

      p{
        @media (screen and max-width: 767px) {
          font-size: 10px;
        }
      }
      .__steps{
        .__process{
          margin: 60px 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @media (screen and max-width: 767px) {
            margin: 25px 0 15px;
          }
          .__column{
            width: 100%;
            height: auto;
            min-height: 40px;
            padding: 0;
            position: relative;
            display: flex;
            justify-content: center;
            border-top: 3px #9D9D9D solid;
            @media (screen and max-width: 767px) {
              min-height: 20px;
            }
            .line{
              position: absolute;
              top: -5px;
              width: 50%;
              height: 10px;
              background-color: #fff;
              z-index: 3;
              &.start{
                left: 0;
                &.finished{
                  background-color: #f0f0f0;
                }
              }
              &.end{
                right: 0;
                &.finished{
                  background-color: #f0f0f0;
                }
              }
            }
            .__date{
              position: absolute;
              top: -35px;
              text-align: center;
              @media (screen and max-width: 767px) {
                top: -18px;
                width: max-content;
              }
              h6{
                font-size: 12px;
                @media (screen and max-width: 767px) {
                  font-size: 6px;
                }
              }
            }
            .circle{
              height: 20px;
              width: 20px;
              border-radius: 50%;
              display: block;
              position: absolute;
              left: 50%;
              top: -11px;
              z-index: 4;
              transform: translateX(-50%);
              background-color: #9D9D9D;
              padding: 0;
              @media (screen and max-width: 767px) {
                height: 10px;
                width: 10px;
                top: -6px;
              }
              &.done{
                background-color: #00ff00;
              }
            }
            .text{
              font-weight: 400;
              margin: 0;
              padding-top: 20px;
              @media (screen and max-width: 767px) {
                padding-top: 5px;
              }
              h5{
                font-size: 18px;
                color: #000;
                @media (screen and max-width: 767px) {
                  font-size: 10px;
                }
                @media (screen and max-width: 350px) {
                  font-size: 8px;
                }
              }
            }
          }
        }
      }

      .total-price{
        margin-bottom: 15px;
        @media (screen and max-width: 767px) {
          margin-bottom: 5px;
        }
        p{
          color: #AAAAAA;
          font-weight: 600;
          font-size: 20px;
          @media (screen and max-width: 767px) {
            font-size: 10px;
          }
          span{
            color: #000;
            font-weight: 700;
          }
        }
      }
      .__btn{
        display: flex;
        gap: 20px;
        @media (screen and max-width: 767px) {
          flex-direction: column;
          gap: 5px;
        }
        button{
          padding: 10px 20px;
          border-radius: 10px;
          font-size: 14px;
          background-color: #1059FF;
          border: 1px solid #1059FF;
          cursor: pointer;
          color: #fff;
          transition: all .3s ease 0s;
          @media (screen and max-width: 767px) {
            font-size: 10px;
            padding: 5px 8px;
            border-radius: 5px;
          }
          &:hover{
            background-color: #5c93ff;
            border: 1px solid #5c93ff;
          }
          &:disabled{
            background-color: #9D9D9D;
            cursor: default;
          }
        }
        .balance_top_up{
          transition: all .3s ease 0s;
          background-color: transparent;
          color: #1059FF;
          border: 1px solid #1059FF;
          &:hover{
            background-color: transparent;
            border: 1px solid #1059FF;
            opacity: .4;
          }
        }
      }
      .error-message{
        font-size: 14px;
        color: red;
        margin-top: 5px;
        font-weight: 600;
        @media (screen and max-width: 767px) {
          font-size: 10px;
          text-align: center;
        }
      }
    }
  }
  .__empty{
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    text-align: center;
    padding-top: 25vh;
    @media (screen and max-width: 767px) {
      padding-top: 20vh;
      width: 95%;
    }
    .__image{
      width: 80px;
      margin-bottom: 10px;
      @media (screen and max-width: 767px) {
        width: 35px;
        margin-bottom: 5px;
      }
      img{
        width: 100%;
      }
    }
    h2{
      color: #0D3BC8;
      @media (screen and max-width: 767px) {
        font-size: 14px;
      }
    }
  }
}