.rules{
  color: #000;
  padding-bottom: 40px;
  @media (screen and max-width: 767px) {
    padding-bottom: 20px;
  }
  h1{
    margin-bottom: 15px;
    @media (screen and max-width: 767px) {
      font-size: 20px;
      text-align: center;
    }
  }
  p{
    margin-bottom: 30px;
    font-size: 18px;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      margin-bottom: 15px;
    }
  }
  h2{
    margin-bottom: 5px;
    @media (screen and max-width: 767px) {
      font-size: 14px;
    }
  }
  .__sublist{
    padding-left: 40px;
    margin-bottom: 10px;
    @media (screen and max-width: 767px) {
      padding-left: 25px;
      font-size: 12px;
    }
    li{
      list-style: decimal;
      margin: 5px;
    }
  }
  .__dot_list{
    padding-left: 40px;
    list-style: disc;
    margin-bottom: 10px;
    @media (screen and max-width: 767px) {
      padding-left: 25px;
      font-size: 12px;
    }
    li{
      list-style: disc;
    }
  }
}