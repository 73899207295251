.privacy{
  color: #000;
  padding-bottom: 40px;
  @media (screen and max-width: 767px) {
    padding-bottom: 20px;
  }
  h1{
    margin-bottom: 15px;
    @media (screen and max-width: 767px) {
      font-size: 20px;
      text-align: center;
    }
  }
  .__list{
    list-style: decimal;
    padding-left: 15px;
    font-size: 18px;
    @media (screen and max-width: 767px) {
      font-size: 14px;
      padding-left: 5px;
    }
    .title{
      font-weight: 600;
    }
    li{
      list-style: inherit;
      margin: 5px;
      @media (screen and max-width: 767px) {
        margin: 3px;
      }
    }

    .__sublist{
      padding-left: 15px;
      @media (screen and max-width: 767px) {
        padding-left: 5px;
        font-size: 12px;
      }
      li{
        list-style: none;
        span{
          padding: 0 3px;
          color: #517fff;
          cursor: pointer;
          text-decoration: underline;
          &:hover{
            opacity: .6;
          }
        }
      }
      .__dot_list{
        padding-left: 40px;
        list-style: disc;

        @media (screen and max-width: 767px) {
          padding-left: 25px;
        }
        li{
          list-style: disc;
        }
      }
    }
  }
}