.product-carousel{
  background-color: #F3F7FE;
  padding: 60px 0;
  position: relative;
  .carousel-info{
    display: flex;
    justify-content: center;
    .carousel-item{
      background-color: #fff;
      width: 60%;
      min-height: 350px;
      display: flex;
      flex-direction: column;
      border-radius: 20px;
      padding-bottom: 20px;
      .hero{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 40px 30px;
        color: #fff;
        img{
          width: 35px;
          margin-right: 5px;
        }
      }
      .info{
        padding: 20px 30px;
        p{
          font-size: 18px;
          text-indent: 10px;
        }
      }
      button{
        width: fit-content;
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        font-size: 18px;
        padding-left: 30px;
        cursor: pointer;
        color: #0D41E1;
        transition: all .3s ease 0s;
        &:hover{
          transform: translateX(10px);
        }
        img{
          width: 20px;
          margin-left: 10px;
        }
      }
    }
  }
  .carousel-button-group{
    .prev{
      position: absolute;
      left: 0;
      top: 35%;
      transform: rotate(180deg);
      cursor: pointer;
    }
    .next{
      position: absolute;
      right: 0;
      top: 35%;
      cursor: pointer;
    }
  }
}

@media (screen and max-width: 767px){
  .product-carousel{
    padding: 30px 0;
    .carousel-info {
      .carousel-item{
        width: 75%;
        min-height: auto;
        .hero{
          padding: 30px 0;
          img{
            width: 25px;
            margin-right: 3px;
          }
          h1{
            font-size: 25px;
          }
        }
        .info{
          padding: 10px 20px;
          p{
            font-size: 16px;
            text-indent: 5px;
          }
        }
        button{
          padding-left: 20px;
          font-size: 14px;
          img{
            width: 15px;
            margin-left: 5px;
          }
        }
      }
    }
    .carousel-button-group{
      .prev{
        width: 20px;
        top: 45%;
      }
      .next{
        width: 20px;
        top: 45%;
      }
    }
  }
}