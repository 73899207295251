.contacts{
  color: #000;
  width: 100%;
  padding-bottom: 150px;
  background: linear-gradient(90deg, #fff 40%, rgba(13,33,161,1) 40%, rgba(13,53,177,1) 73%, rgba(13,59,200,1) 100%);
  padding-top: 130px;
  display: flex;
  justify-content: space-between;
  @media (screen and max-width: 767px){
    padding-top: 75px;
    background: #fff;
    flex-direction: column;
    padding-bottom: 0;
  }
  .contact-us{
    width: 25%;
    padding-left: 10vw;
    @media (screen and max-width: 767px){
      width: 100%;
      padding: 0 0 20px;
      text-align: center;
    }
    h1 {
      font-size: 30px;
      margin-bottom: 60px;
      color: #000;
      @media (screen and max-width: 767px){
        text-align: center;
        margin-bottom: 20px;
        font-size: 20px;
      }
    }
    .contact-info{
      margin-bottom: 20px;
      @media (screen and max-width: 767px){
        margin-bottom: 15px;
      }
      p{
        font-size: 18px;
        color: #AAAAAA;
        font-weight: 600;
        margin-bottom: 10px;
        @media (screen and max-width: 767px){
          font-size: 12px;
          margin-bottom: 5px;
        }
      }
      span{
        color: #000;
        font-size: 20px;
        display: inline-block;
        max-width: 80%;
        @media (screen and max-width: 767px){
          font-size: 16px;
        }
      }
      ul{
        li{
          a{
            color: #000;
            font-size: 20px;
            @media (screen and max-width: 767px){
              font-size: 16px;
            }
          }
        }
      }
      .social-wrapper{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        @media (screen and max-width: 767px){
          justify-content: center;
        }
        a{
          img{
            margin-right: 20px;
            width: 30px;
            transition: all 0.3s ease 0s;
            &:hover{
              transition: all 0.3s ease 0s;
              transform: scale(1.2);
            }
            @media (screen and max-width: 767px){
              width: 20px;
              margin: 0 10px;
            }
          }
        }
      }
    }
  }
  .contact-form{
    width: 65%;
    display: flex;
    justify-content: center;
    @media (screen and max-width: 767px){
      width: 100%;
      background: linear-gradient(90deg, rgba(13,33,161,1) 0%, rgba(13,53,177,1) 33%, rgba(13,59,200,1) 70%, rgba(13,65,225,1) 100%);
      padding: 40px 0;
    }
    .form{
      background-color: #fff;
      border-radius: 25px;
      padding: 25px;
      width: 40%;
      height: fit-content;
      @media(screen and max-width: 1240px){
        width: 70%;
        border-radius: 20px;
      }
      .label-group{
        display: flex;
        flex-direction: column;
        label{
          color: #0D41E1;
          font-size: 18px;
          margin-bottom: 15px;
          @media (screen and max-width: 767px){
            font-size: 14px;
            margin-bottom: 8px;
          }
          span{
            color: red;
          }
        }
        input{
          box-shadow: 0 0 4px 0 #00000040;
          font-size: 16px;
          padding: 15px 20px;
          border-radius: 25px;
          border: none;
          margin-bottom: 10px;
          @media (screen and max-width: 767px){
            font-size: 12px;
            padding: 10px;
            border-radius: 10px;
            margin-bottom: 10px;
          }
        }
        input.invalid{
          outline: 1px solid red;
          box-shadow: 0 0 5px red;
        }
        textarea{
          border: none;
          box-shadow: 0 0 4px 0 #00000040;
          resize: none;
          min-height: 80px;
          border-radius: 25px;
          padding: 15px 20px;
          font-size: 16px;
          margin-bottom: 30px;
          @media (screen and max-width: 767px){
            font-size: 12px;
            padding: 10px;
            border-radius: 10px;
            min-height: 60px;
          }
        }
        .error-message{
          font-size: 14px;
          text-align: center;
          color: red;
          @media (screen and max-width: 767px){
            font-size: 10px;
          }
        }
      }
      button{
        background-color: #0D41E1FC;
        border: none;
        color: #fff;
        padding: 15px;
        width: 100%;
        border-radius: 25px;
        font-size: 18px;
        cursor: pointer;
        transition: all .3s ease 0s;
        @media (screen and max-width: 767px){
          font-size: 14px;
          padding: 10px;
          border-radius: 10px;
        }
        &:hover{
          opacity: .7;
        }
      }
      .form-agree{
        .agree-text{
          margin-top: 10px;
          cursor: pointer;
          font-size: 16px;
          color: #C0C0C0;
          line-height: 23.44px;
          text-align: center;
          @media (screen and max-width: 767px){
            text-align: left;
            font-size: 12px;
            display: flex;
            align-items: center;
            line-height: 12px;
          }
          input{
            margin-right: 5px;
            @media (screen and max-width: 767px){
              width: 10px;
            }
          }
          label{
            cursor: pointer;
            a{
              color: #0F43E1;
              font-weight: 600;
            }
            span{
              color: red;
            }
          }
        }
        .error-message{
          color: red;
          margin-top: 5px;
          font-size: 14px;
          text-align: center;
          @media (screen and max-width: 767px) {
            font-size: 10px;
            margin-left: 20px;
            text-align: left;
          }
        }
      }
    }
  }
}
