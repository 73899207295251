.__services_info{
  padding: 30px 0 60px;
  @media (screen and max-width: 767px) {
    padding: 0 0 30px;
  }
  .__header{
    background-color: #0a194f;
    width: 100%;
    padding: 0 0 60px;
    @media (screen and max-width: 767px) {
      padding: 0 0 30px;
    }

  }
  .__title{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
    padding: 15px 5vw;
    margin-bottom: 30px;
    @media (screen and max-width: 767px) {
      flex-direction: column;
      padding: 0;
      margin-bottom: 0;
      gap: 15px;
    }
    .__logo{
      width: 50%;
      display: flex;
      justify-content: flex-end;
      @media (screen and max-width: 767px) {
        order: -1;
        width: 100%;
        justify-content: center;

      }
      img{
        width: 80%;
        @media (screen and max-width: 767px) {
          width: 50%;
          border-radius: 10px;
        }
      }

    }
    .__hero{
      width: 50%;
      @media (screen and max-width: 767px) {
        width: 85%;
      }

      h1{
        color: #fff;
        text-align: center;
        margin-bottom: 40px;
        @media (screen and max-width: 767px) {
          font-size: 20px;
          margin-bottom: 15px;
        }
      }
      p{
        color: #fff;
        font-weight: 500;
        text-indent: 10px;
        font-size: 16px;
        margin-bottom: 30px;
        @media (screen and max-width: 767px) {
          text-align: center;
          font-size: 10px;
          margin-bottom: 15px;
        }
      }
      h4{
        margin-bottom: 40px;
        color: #fff;
        @media (screen and max-width: 767px) {
          font-size: 12px;
          text-align: center;
          margin-bottom: 25px;
        }
      }
      .__btn_group{
        display: flex;
        justify-content: center;
        gap: 50px;
        @media (screen and max-width: 767px) {
          flex-direction: column;
          gap: 0;
        }
        button{
          background-color: #0D41E1;
          border: none;
          color: #fff;
          padding: 10px 30px;
          border-radius: 15px;
          cursor: pointer;
          font-size: 16px;
          transition: all .3s ease 0s;
          font-weight: 600;
          display: inline-block;
          @media (screen and max-width: 767px) {
            font-size: 12px;
            padding: 10px 20px;
            border-radius: 5px;
          }
          &:last-child{
            background-color: #fff;
            color: #000;
            @media (screen and max-width: 767px) {
              margin-top: 5px;
            }
            &:hover{
              background-color: #9D9D9D;
            }
          }
          &:hover{
            background-color: #4f83ff;
          }

        }
      }
    }
  }
  .__steps{
    h2{
      text-align: center;
      padding-top: 50px;
      @media (screen and max-width: 767px) {
        padding-top: 20px;
        font-size: 16px;
      }
    }
    .__process{
      margin: 60px 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-left: 30px;
      @media (screen and max-width: 767px) {
        padding-left: 0;
        margin: 20px 0;
      }
      .__column{
        width: 100%;
        height: auto;
        min-height: 75px;
        padding: 0;
        position: relative;
        display: flex;
        flex-direction: row;
        border-left: 3px #0D41E1 solid;
        &:last-child{
          border-left: 3px transparent solid;
        }
        @media (screen and max-width: 767px) {
          min-height: 35px;
          padding-bottom: 15px;
        }
        .line{
          border-bottom: 0;
          height: 12px;
          width: 100%;
          padding: 0;
          margin: 0;
          max-width: 30px;
          @media (screen and max-width: 767px) {
            max-width: 20px;
          }
          .circle{
            height: 20px;
            width: 20px;
            margin-left: -34px;
            border-radius: 50%;
            display: block;
            position: absolute;
            left: 20px;
            border: 3px #0D41E1 solid;
            background-color: #fff;
            padding: 0;
            @media (screen and max-width: 767px) {
              height: 10px;
              width: 10px;
              margin-left: -29px;
            }

          }
        }
        .title{
          font-weight: 400;
          margin: 0;
          padding: 0;
          h5{
            font-size: 18px;
            line-height: 24px;
            text-align: left;
            @media (screen and max-width: 767px) {
              line-height: inherit;
              font-size: 12px;
            }
          }
          p{
            text-indent: 10px;
            @media (screen and max-width: 767px) {
              text-indent: 5px;
              font-size: 10px;
            }
          }
        }
      }


    }

  }
  .__technologies{
    h2{
      text-align: center;
      @media (screen and max-width: 767px) {
        font-size: 16px;
      }
    }
    .__items{
      margin: 30px 0;
      display: flex;
      gap: 100px;
      justify-content: center;
      align-items: center;
      @media (screen and max-width: 767px) {
        gap: 50px;
      }
      .card{
        display: flex;
        flex-direction: column;
        align-items: center;
        .image{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100px;
          height: 100px;
          @media (screen and max-width: 767px) {
            width: 40px;
            height: 40px;
          }
          img{
            width: 100%;
          }
        }
        p{
          margin-top: 10px;
          text-align: center;
          font-weight: 700;
          @media (screen and max-width: 767px) {
            font-size: 12px;
          }
        }
      }
    }
  }
}
