.account{
  width: 100%;
  .__header{
    display: flex;
    justify-content: flex-start;
    margin-bottom: 150px;
    @media (screen and max-width: 1040px) {
      justify-content: space-between;
    }
    @media (screen and max-width: 767px) {
      align-items: center;
      position: relative;
      margin-bottom: 40px;
    }
    .__title{
      display: flex;
      align-items: center;
      padding-right: 50px;
      border-right: 1px solid rgb(128, 128, 128);
      @media (screen and max-width: 1024px) {
        padding-right: 20px;
      }
      @media (screen and max-width: 767px) {
        border-right: none;
      }
      img{
        width: 40px;
        height: 40px;
        margin-right: 10px;
        @media (screen and max-width: 767px) {
          width: 20px;
          height: 20px;
          margin-right: 5px;
        }
      }
      p{
        font-weight: 600;
        @media (screen and max-width: 767px) {
          font-size: 12px;
        }
      }
    }
    .__info{
      width: 60%;
      padding-left: 50px;
      display: flex;
      justify-content: space-around;
      transition: all .3s ease-in-out 0s;
      @media (screen and max-width: 1024px) {
        padding-left: 20px;
      }
      @media (screen and max-width: 767px) {
        display: none;
        height: 0;
      }
      .__item{
        line-height: 1.25rem;
        text-align: center;
        p{
          color: #848E9C;
          margin-bottom: 10px;
          @media (screen and max-width: 767px) {
            margin-bottom: 0;
            font-size: 12px;
          }
        }
        span{
          @media (screen and max-width: 767px) {
            font-size: 10px;
          }
        }
        .logout_img{
          width: 20px;
          height: 20px;
          cursor: pointer;
          @media (screen and max-width: 767px) {
            height: 15px;
            width: 15px;
          }
          &:hover{
            path{
              stroke: #ff0000;
              transition: all .4s ease-in-out 0s;
            }
          }
        }
      }
    }
    .__info.active{
      @media (screen and max-width: 767px) {
        height: max-content;
        display: block;
        width: 95%;
        background-color: #fff;
        position: absolute;
        top: 30px;
        left: 0;
        padding: 10px;
        transition: all .3s ease-in-out 0s;
        .__item{
          width: 100%;
          text-align: initial;
          display: flex;
          justify-content: space-between;
        }
      }

    }
    .__dropdown{
      display: none;
      @media (screen and max-width: 767px) {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 8px;
        width: 20px;
        height: 20px;
        transition: all .3s ease-in-out 0s;
      }
      .active{
        transform: rotate(180deg);
        transition: all .3s ease-in-out 0s;
      }
    }
  }
  .history_wrapper{
    padding: 0 40px;
    width: 80%;
    @media (screen and max-width: 767px) {
      padding: 0 10px;
      width: 95%;
    }
    h2{
      margin-bottom: 40px;
      @media (screen and max-width: 767px) {
        margin-bottom: 20px;
        font-size: 16px;
      }
      @media (screen and max-width: 375px) {
        margin-bottom: 5px;
        font-size: 14px;
      }
    }
  }
}